import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //存数据
  state: {
    particulars : {},
    orderSite : {
      siteName : '',
      siteAddress : '',
      sitePhone : '',
      id : null
    },
    orderData : {
      commodityId: 0,
      rentDay: 0,
    },
    addressShow:'',
  },
  //创建方法 通过方法绑定参数
  mutations: {
    setParticulars(state,data){
      state.particulars = data
    },
    setOrderSite(state,data){
      state.orderSite = data
    },
    setOrderData(state,data){
      state.orderData = data
    },
    setAddressShow(state,data){
      state.addressShow = data
    }
  },
  actions: {
  },
  modules: {
  }
})
