import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'layout', // 如果父路由有默认子路由，那它的 name 没有意义
    component: () => import('@/views/index'),
    children: [
      {
        path: '', // 默认子路由，只能有1个,首页组件位置
        name: 'home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/share',//测绘玩家经销商分享页面
        name: 'Share',
        component: () => import('@/views/share/index.vue')
      },
      {
        path: '/video',//视频
        name: 'video',
        component: () => import('@/views/video/index.vue'),
        children: [
          {
            path: '', // //视频教程
            name: 'course',
            component: () => import('@/views/video/child/course.vue')
          },
          {
            path: 'rtkPage',//评测
            name: 'rtkPage',
            component: () => import('@/views/video/child/rtkPage.vue')
          },
          {
            path: 'certificate',//鉴定证书
            name: 'certificate',
            component: () => import('@/views/video/child/certificate.vue')
          },
          {
            path: 'accredit',//授权证书
            name: 'accredit',
            component: () => import('@/views/video/child/accredit.vue')
          },
          {
            path: 'award',//获奖证书
            name: 'award',
            component: () => import('@/views/video/child/award.vue')
          }
        ]
      },
      {
        path: '/branch',//网点
        name: 'branch',
        component: () => import('@/views/branch/index.vue')
      },
      {
        path: '/classification',//资料
        name: 'Classification',
        component: () => import('@/classification/index.vue')
      },
      {
        path: '/classProduct',//资料
        name: 'classProduct',
        component: () => import('@/classification/components/classProduct.vue')
      },
      {
        path: '/skill',//技术
        name: 'skill',
        component: () => import('@/views/skill/index.vue')
      },
      {
        path: '/transitPage',//IOS技术中转页面
        name: 'transitPage',
        component: () => import('@/views/transitPage/index.vue')
      },
      {
        path: '/code',//激活码
        name: 'code',
        component: () => import('@/views/my/activate.vue')
      },
      {
        path: '/maintain',//维修查询
        name: 'maintain',
        component: () => import('@/views/maintain/index.vue')
      },
      {
        path: '/my',//我的
        name: 'my',
        component: () => import('@/views/my/index.vue')
      }
    ]
  },
    // 资料网
  // {
  //   path: '/classification',
  //   name: 'Classification',
  //   component: () => import('@/classification/index.vue'),
  // },
  // 租赁
  {
    path: '/lease',
    name: 'lease', // 如果父路由有默认子路由，那它的 name 没有意义
    component: () => import('@/lease/index.vue'),
    children: [
        // 首页
      {
        path: '',
        name: 'home', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/home/home.vue'),
      },
        // 租赁大攻略
      {
        path: 'rentThings',
        name: 'rentThings',
        component:() =>import('@/lease/home/components/rentThings')
      },
      // 分类
      {
        path: '/classify',//子路由加斜杠为根路由，不加为子路由路径
        name: 'classify', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/classify/classify.vue'),
      },
        // 客服
      {
        path: '/service',
        name: 'service', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/service/service.vue'),
      },
        // 订单
      {
        path: '/order',
        name: 'order', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/order/order.vue'),
      },
      // 历史订单
      {
        path: '/orderHistory',
        name: 'orderHistory', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/order/components/orderHistory.vue'),
      },
        // 个人中心
      {
        path: '/personage',
        name: 'personage', // 如果父路由有默认子路由，那它的 name 没有意义
        component: () => import('@/lease/personage/personage.vue'),
      },
        // 产品详情
      {
        path:'/product',
        name: 'productDetails',
        component:()=>import('@/lease/product/product.vue')
      },
      // 我的确认订单
      {
        path:'/myOrderDetails',
        name: 'myOrderDetails',
        component:()=>import('@/lease/product/components/myOrderDetails.vue')
      },
        // 全部评论
      {
        path:'/allViewComments',
        name: 'allViewComments',
        component:()=>import('@/lease/product/components/allViewComments.vue')
      },
      // 免押协议
      {
        path:'/leasingAgreement',
        name: 'leasingAgreement',
        component:()=>import('@/lease/product/components/leasingAgreement.vue')
      },
        // 我的评价订单
      {
        path:'/myOrder',
        name: 'myOrder',
        component:()=>import('@/lease/personage/components/myOrder.vue')
      },
        // 新增地址
      {
        path:'/theNewAddress',
        name: 'theNewAddress',
        component:()=>import('@/lease/personage/components/theNewAddress.vue')
      },
        // 地址
      {
        path:'/deliveryAddress',
        name: 'deliveryAddress',
        component:()=>import('@/lease/personage/components/deliveryAddress.vue')
      },
      // 发票申请
      {
        path:'/InvoiceToApplyFor',
        name: 'InvoiceToApplyFor',
        component:()=>import('@/lease/personage/components/InvoiceToApplyFor.vue')
      },
      // 免押认证
      {
        path:'/freeAndCertification',
        name: 'freeAndCertification',
        component:()=>import('@/lease/personage/components/freeAndCertification.vue')
      },
       // 我的发票
      // 发票申请
      {
        path:'/myInvoice',
        name: 'myInvoice',
        component:()=>import('@/lease/personage/components/myInvoice.vue')
      },
      //人脸识别测试
      {
        path:'/test',
        name: 'test',
        component:()=>import('@/lease/service/test.vue')
      },
    ]
  },

  {
    path: '/courseList:userId',//视频教程列表
    name: 'courseList',
    component: () => import('@/views/video/course-list.vue')
  },{
    path: '/playVideo',//视频播放页面
    name: 'playVideo',
    component: () => import('@/views/video/play-video.vue')
  },
  {
    path: '/certificateList:certificateId',//证书列表
    name: 'certificateList',
    component: () => import('@/views/video/certificate-list.vue')
  },
  {
    path: '/activate',//激活码
    name: 'activate',
    component: () => import('@/views/my/activate.vue')
  },
  {
    path: '/postMessage',//发布信息
    name: 'postMessage',
    component: () => import('@/views/my/postMessage.vue')
  },
  {
    path: '/msgDetail',//信息详情
    name: 'msgDetail',
    component: () => import('@/views/home/msgDetail')
  },
  {
    path: '/login',//登录
    name: 'login',
    component: () => import('@/views/my/login.vue')
  },
  {
    path: '/linkPage:url',//打开第三方旗下页面
    name: 'linkPage',
    component: () => import('@/views/home/linkPage.vue')
  },
  {
    path: '/shouban',//上传图片
    name: 'shouBan',
    component: () => import('@/views/shouban')
  },
]

const router = new VueRouter({
  routes
})

export default router
